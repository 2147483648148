/**
 * @see: https://mdbootstrap.com/docs/react/advanced/charts/
 * @see: https://github.com/jerairrest/react-chartjs-2
 * @see: https://github.com/jerairrest/react-chartjs-2/blob/master/example/src/components/bar.js
 * @see: https://jsfiddle.net/simonbrunel/y1ry2cvb/
 */
import React from "react";
import { defaults, Bar } from 'react-chartjs-2';
// import { MDBContainer } from "mdbreact";

import { merge } from 'lodash';
import GeneralUtils from './../../utils/common'
import axios from 'axios';

// @see: https://github.com/jerairrest/react-chartjs-2#chartjs-defaults

merge(defaults, {
  global: {
    animation: false,
    line: {
      borderColor: '#F85F73',
     },
        // This more specific font property overrides the global property
        fontColor: 'black',
        fontFamily: "'Font Awesome 5 Free'",
        fontSize: 26,
  },
});


const CHART_DATA_DATASETS_DATA_DEFAULT = [0, 0, 10, 20, 70];


class StatefullComponent extends React.Component {

    blueLight = '#94add6';
    blue = '#243674';
    orange = '#ff9600';
    grey = '#9a9a99';
    
    state = {
        isFetching: false,
        data: {
        //   labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
            labels: [
                // '<i class="far fa-sad-cry"></i>', 
                '\u{F5B3}',
                '\u{f5b3}',
                '<i class="far fa-meh"></i>',
                '<i class="far fa-smile"></i>',
                '<i class="far fa-smile-beam"></i>',
            ],
            datasets: [{
                // label: "% of Votes",
                // label: 'Ocena satysfakcji z poszczególnych aspektów funkcjonowania Firmy \uF5B3',
                // label: 'Ocena satysfakcji z poszczególnych aspektów funkcjonowania Firmy',
                data: CHART_DATA_DATASETS_DATA_DEFAULT,
                backgroundColor: `rgba(${GeneralUtils.hexToRgb(this.orange).join(',')}, 0.25)`,
                borderColor: `rgba(${GeneralUtils.hexToRgb(this.orange).join(',')}, 1)`,
                borderWidth: 1,
                hoverBackgroundColor: `rgba(${GeneralUtils.hexToRgb(this.orange).join(',')}, 1)`,
                hoverBorderColor: `rgba(${GeneralUtils.hexToRgb(this.orange).join(',')}, 1)`,
            }]
        },
        options: {
            responsive: true,
            // maintainAspectRatio: false,
            // animation: false,
            // legend: false,
            tooltips: false,
            // layout: {
            //     padding: 24,
            // },
            scales: {
                xAxes: [{
                    barPercentage: 1,
                    gridLines: {
                        display: true,
                        color: "rgba(0, 0, 0, 0.1)"
                    },

                    display: false,
                    scaleLabel: {
                        display: false,
                        labelString: 'Frequency',
                        fontColor: 'black',
                        fontFamily: "'Font Awesome 5 Free'",
                        fontSize: 26,
                    },
                    // label: {
                    //     // This more specific font property overrides the global property
                    //     fontColor: 'black',
                    //     fontFamily: "'Font Awesome 5 Free'",
                    //     fontSize: 26,
                    // },
                    // legend: {
                    //     labels: {
                    //         // This more specific font property overrides the global property
                    //         fontColor: 'black',
                    //         fontFamily: "'Font Awesome 5 Free'",
                    //         fontSize: 26,
                    //     },
                    // },

                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        color: "rgba(0, 0, 0, 0.1)"
                    },
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
            // plugins: {
            //     datalabels: {
            //         align: 'end',
            //         anchor: 'end',
            //         // backgroundColor: function(context) {
            //         //     return context.dataset.backgroundColor;
            //         // },
            //         borderRadius: 4,
            //         color: '#000',
            //         // formatter: function(value){
            //         //     return value + ' (100%) ';
            //         // }
            //         formatter: function(value, context) {
            //             return context.dataset.icons[context.dataIndex];
            //         },
            //     }
            // },
            legend: {
                display: false,
            },
            // legend: {
            //     labels: {
            //         // This more specific font property overrides the global property
            //         fontColor: 'black',
            //         fontFamily: "'Font Awesome 5 Free'",
            //         fontSize: 26,
            //     },
            // },
        }
    }

    // intl = {
    //     locale: `pl`
    // }

    loadChartData = async () => {
        const formSchemaEndpoint = `/soft4net/webform/submissions/get`;

        let response = null, 
            data = CHART_DATA_DATASETS_DATA_DEFAULT;

        this.setState({...this.state, isFetching: true});

        try {
            // response = await axios.get(`${process.env.SOFT4NET_SOURCE_BASE_URL}/${this.intl.locale}${formSchemaEndpoint}`);
            response = await axios.get(`${process.env.SOFT4NET_SOURCE_BASE_URL}${formSchemaEndpoint}`);
            data = response.data;
            this.setState({ 
                ...this.state,
                data: {
                    ...this.state.data,
                    datasets: [{
                        ...this.state.data.datasets[0],
                        data: response.data,
                    }]
                }
            });
        } catch (e) {
            console.log(e);
        }
        
        this.setState({...this.state, isFetching: false});

        return data;
    }

    componentDidMount() {        
        this.loadChartData();
    }

    render() {
        if (this.state.isFetching) {
            return 'Loading...';
        }

        // return <Bar data={this.state.data} options={this.state.options} />
        // return (
        //   <React.Fragment>
        //     <h3 className="mt-5">Bar chart</h3>
        //     <Bar data={this.state.data} options={this.state.options} />
        //   </React.Fragment>
        // );
        return (
          <React.Fragment>
            {/* <h3 className="mt-5">Bar chart</h3> */}
            <Bar data={this.state.data} options={this.state.options} />
            <div className="container-fluid pl-5 chart-labels">
                <div className="row">
                    <div className="col text-center"><i className="far fa-sad-cry"></i></div>
                    <div className="col text-center"><i className="far fa-frown"></i></div>
                    <div className="col text-center"><i className="far fa-meh"></i></div>
                    <div className="col text-center"><i className="far fa-smile"></i></div>
                    <div className="col text-center"><i className="far fa-smile-beam"></i></div>
                </div>
            </div>
          </React.Fragment>
        );
    }
}

export default StatefullComponent;



/*

const data = {
    datasets: [{
        label: 'Ocena satysfakcji z poszczególnych aspektów funkcjonowania Firmy \uF5B3',
        backgroundColor: `rgba(${GeneralUtils.hexToRgb(orange).join(',')}, 0.2)`,
        borderColor: `rgba(${GeneralUtils.hexToRgb(orange).join(',')}, 1)`,
        borderWidth: 1,
        hoverBackgroundColor: `rgba(${GeneralUtils.hexToRgb(orange).join(',')}, 1)`,
        hoverBorderColor: `rgba(${GeneralUtils.hexToRgb(orange).join(',')}, 1)`,
        data: [20, 40, 60, 80, 100],
        icons: ['\u{F5B3}', '\u{f5b3}', '\uF5B3', '\uf5b3', '\uf5b3'],
    }],

    labels: [
        // '<i class="far fa-sad-cry"></i>', 
        '\u{F5B3}',
        '\u{f5b3}',
        '<i class="far fa-meh"></i>',
        '<i class="far fa-smile"></i>',
        '<i class="far fa-smile-beam"></i>',
    ],

    // legendCallback: function(chart) { 
    //     var text = []; 
    //     text.push('<ul class="' + chart.id + '-legend">'); 
    //     for (var i = 0; i < chart.data.datasets.length; i++) { 
    //         text.push('<li><span style="background-color:' + 
    //                    chart.data.datasets[i].backgroundColor + 
    //                    '"></span>'); 
    //         if (chart.data.datasets[i].label) { 
    //             text.push(chart.data.datasets[i].label); 
    //         } 
    //         text.push('</li>'); 
    //     } 
    //     text.push('</ul>'); 
    //     return text.join(''); 
    // }
};

const options = {
    // defaultFontFamily: "'Font Awesome 5 Free'",
    // elements: {
    //     rectangle: {
    //         backgroundColor: "#cc55aa",
    //     }
    // },
    // plugins: {
    //     datalabels: {
    //         align: 'end',
    //         anchor: 'end',
    //         color: "#cc55aa",
    //         font: {
    //             family: 'Font Awesome 5 Free',
    //             size: 20,
    //         },
    //         formatter: function(value, context) {
    //             return context.dataset.icons[context.dataIndex];
    //         }
    //     }
    // },

    scales: {
        xAxes: [{
            display: true,
            scaleLabel: {
                display: true,
                labelString: 'Frequency',
                fontColor: 'black',
                fontFamily: "'Font Awesome 5 Free'",
                fontSize: 26,
            },
            label: {
                // This more specific font property overrides the global property
                fontColor: 'black',
                fontFamily: "'Font Awesome 5 Free'",
                fontSize: 26,
            },
            legend: {
                labels: {
                    // This more specific font property overrides the global property
                    fontColor: 'black',
                    fontFamily: "'Font Awesome 5 Free'",
                    fontSize: 26,
                },
            },
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
                // // Include a dollar sign in the ticks
                // callback: function(value, index, values) {
                //     return '$' + value;
                // }
            },
            scaleLabel: {
                fontColor: 'black',
                fontFamily: "'Font Awesome 5 Free'",
                fontSize: 26,
            },
        }]
    },

    plugins: {
        datalabels: {
            align: 'end',
            anchor: 'end',
            // backgroundColor: function(context) {
            //     return context.dataset.backgroundColor;
            // },
            borderRadius: 4,
            color: '#000',
            // formatter: function(value){
            //     return value + ' (100%) ';
            // }
            formatter: function(value, context) {
                return context.dataset.icons[context.dataIndex];
            },
        }
    },
}
*/